// Generated from Cypher.g4 by ANTLR 4.13.1

import * as antlr from "antlr4ng";
import { Token } from "antlr4ng";


export class CypherLexer extends antlr.Lexer {
    public static readonly T__0 = 1;
    public static readonly T__1 = 2;
    public static readonly T__2 = 3;
    public static readonly T__3 = 4;
    public static readonly T__4 = 5;
    public static readonly T__5 = 6;
    public static readonly T__6 = 7;
    public static readonly T__7 = 8;
    public static readonly T__8 = 9;
    public static readonly T__9 = 10;
    public static readonly T__10 = 11;
    public static readonly T__11 = 12;
    public static readonly T__12 = 13;
    public static readonly T__13 = 14;
    public static readonly T__14 = 15;
    public static readonly T__15 = 16;
    public static readonly T__16 = 17;
    public static readonly T__17 = 18;
    public static readonly T__18 = 19;
    public static readonly T__19 = 20;
    public static readonly T__20 = 21;
    public static readonly T__21 = 22;
    public static readonly T__22 = 23;
    public static readonly T__23 = 24;
    public static readonly T__24 = 25;
    public static readonly T__25 = 26;
    public static readonly T__26 = 27;
    public static readonly T__27 = 28;
    public static readonly T__28 = 29;
    public static readonly T__29 = 30;
    public static readonly T__30 = 31;
    public static readonly T__31 = 32;
    public static readonly T__32 = 33;
    public static readonly T__33 = 34;
    public static readonly T__34 = 35;
    public static readonly T__35 = 36;
    public static readonly T__36 = 37;
    public static readonly T__37 = 38;
    public static readonly T__38 = 39;
    public static readonly T__39 = 40;
    public static readonly T__40 = 41;
    public static readonly T__41 = 42;
    public static readonly T__42 = 43;
    public static readonly T__43 = 44;
    public static readonly T__44 = 45;
    public static readonly ADD = 46;
    public static readonly ALL = 47;
    public static readonly ALTER = 48;
    public static readonly AND = 49;
    public static readonly AS = 50;
    public static readonly ASC = 51;
    public static readonly ASCENDING = 52;
    public static readonly ATTACH = 53;
    public static readonly BEGIN = 54;
    public static readonly BY = 55;
    public static readonly CALL = 56;
    public static readonly CASE = 57;
    public static readonly CAST = 58;
    public static readonly CHECKPOINT = 59;
    public static readonly COLUMN = 60;
    public static readonly COMMENT = 61;
    public static readonly COMMIT = 62;
    public static readonly COMMIT_SKIP_CHECKPOINT = 63;
    public static readonly CONTAINS = 64;
    public static readonly COPY = 65;
    public static readonly COUNT = 66;
    public static readonly CREATE = 67;
    public static readonly CYCLE = 68;
    public static readonly DATABASE = 69;
    public static readonly DBTYPE = 70;
    public static readonly DEFAULT = 71;
    public static readonly DELETE = 72;
    public static readonly DESC = 73;
    public static readonly DESCENDING = 74;
    public static readonly DETACH = 75;
    public static readonly DISTINCT = 76;
    public static readonly DROP = 77;
    public static readonly ELSE = 78;
    public static readonly END = 79;
    public static readonly ENDS = 80;
    public static readonly EXISTS = 81;
    public static readonly EXPLAIN = 82;
    public static readonly EXPORT = 83;
    public static readonly EXTENSION = 84;
    public static readonly FALSE = 85;
    public static readonly FROM = 86;
    public static readonly GLOB = 87;
    public static readonly GRAPH = 88;
    public static readonly GROUP = 89;
    public static readonly HEADERS = 90;
    public static readonly HINT = 91;
    public static readonly IMPORT = 92;
    public static readonly IF = 93;
    public static readonly IN = 94;
    public static readonly INCREMENT = 95;
    public static readonly INSTALL = 96;
    public static readonly IS = 97;
    public static readonly JOIN = 98;
    public static readonly KEY = 99;
    public static readonly LIMIT = 100;
    public static readonly LOAD = 101;
    public static readonly MACRO = 102;
    public static readonly MATCH = 103;
    public static readonly MAXVALUE = 104;
    public static readonly MERGE = 105;
    public static readonly MINVALUE = 106;
    public static readonly MULTI_JOIN = 107;
    public static readonly NO = 108;
    public static readonly NODE = 109;
    public static readonly NOT = 110;
    public static readonly NULL = 111;
    public static readonly ON = 112;
    public static readonly ONLY = 113;
    public static readonly OPTIONAL = 114;
    public static readonly OR = 115;
    public static readonly ORDER = 116;
    public static readonly PRIMARY = 117;
    public static readonly PROFILE = 118;
    public static readonly PROJECT = 119;
    public static readonly RDFGRAPH = 120;
    public static readonly READ = 121;
    public static readonly REL = 122;
    public static readonly RENAME = 123;
    public static readonly RETURN = 124;
    public static readonly ROLLBACK = 125;
    public static readonly ROLLBACK_SKIP_CHECKPOINT = 126;
    public static readonly SEQUENCE = 127;
    public static readonly SET = 128;
    public static readonly SHORTEST = 129;
    public static readonly START = 130;
    public static readonly STARTS = 131;
    public static readonly TABLE = 132;
    public static readonly THEN = 133;
    public static readonly TO = 134;
    public static readonly TRANSACTION = 135;
    public static readonly TRUE = 136;
    public static readonly TYPE = 137;
    public static readonly UNION = 138;
    public static readonly UNWIND = 139;
    public static readonly USE = 140;
    public static readonly WHEN = 141;
    public static readonly WHERE = 142;
    public static readonly WITH = 143;
    public static readonly WRITE = 144;
    public static readonly XOR = 145;
    public static readonly DECIMAL = 146;
    public static readonly STAR = 147;
    public static readonly L_SKIP = 148;
    public static readonly INVALID_NOT_EQUAL = 149;
    public static readonly MINUS = 150;
    public static readonly FACTORIAL = 151;
    public static readonly COLON = 152;
    public static readonly StringLiteral = 153;
    public static readonly EscapedChar = 154;
    public static readonly DecimalInteger = 155;
    public static readonly HexLetter = 156;
    public static readonly HexDigit = 157;
    public static readonly Digit = 158;
    public static readonly NonZeroDigit = 159;
    public static readonly NonZeroOctDigit = 160;
    public static readonly ZeroDigit = 161;
    public static readonly RegularDecimalReal = 162;
    public static readonly UnescapedSymbolicName = 163;
    public static readonly IdentifierStart = 164;
    public static readonly IdentifierPart = 165;
    public static readonly EscapedSymbolicName = 166;
    public static readonly SP = 167;
    public static readonly WHITESPACE = 168;
    public static readonly CypherComment = 169;
    public static readonly Unknown = 170;

    public static readonly channelNames = [
        "DEFAULT_TOKEN_CHANNEL", "HIDDEN"
    ];

    public static readonly literalNames = [
        null, "';'", "'('", "','", "')'", "'.'", "'='", "'['", "']'", "'{'", 
        "'}'", "'|'", "'..'", "'<>'", "'<'", "'<='", "'>'", "'>='", "'&'", 
        "'>>'", "'<<'", "'+'", "'/'", "'%'", "'^'", "'=~'", "'$'", "'\\u27E8'", 
        "'\\u3008'", "'\\uFE64'", "'\\uFF1C'", "'\\u27E9'", "'\\u3009'", 
        "'\\uFE65'", "'\\uFF1E'", "'\\u00AD'", "'\\u2010'", "'\\u2011'", 
        "'\\u2012'", "'\\u2013'", "'\\u2014'", "'\\u2015'", "'\\u2212'", 
        "'\\uFE58'", "'\\uFE63'", "'\\uFF0D'", null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, "'*'", null, "'!='", 
        "'-'", "'!'", "':'", null, null, null, null, null, null, null, null, 
        "'0'"
    ];

    public static readonly symbolicNames = [
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, "ADD", "ALL", "ALTER", "AND", "AS", "ASC", "ASCENDING", 
        "ATTACH", "BEGIN", "BY", "CALL", "CASE", "CAST", "CHECKPOINT", "COLUMN", 
        "COMMENT", "COMMIT", "COMMIT_SKIP_CHECKPOINT", "CONTAINS", "COPY", 
        "COUNT", "CREATE", "CYCLE", "DATABASE", "DBTYPE", "DEFAULT", "DELETE", 
        "DESC", "DESCENDING", "DETACH", "DISTINCT", "DROP", "ELSE", "END", 
        "ENDS", "EXISTS", "EXPLAIN", "EXPORT", "EXTENSION", "FALSE", "FROM", 
        "GLOB", "GRAPH", "GROUP", "HEADERS", "HINT", "IMPORT", "IF", "IN", 
        "INCREMENT", "INSTALL", "IS", "JOIN", "KEY", "LIMIT", "LOAD", "MACRO", 
        "MATCH", "MAXVALUE", "MERGE", "MINVALUE", "MULTI_JOIN", "NO", "NODE", 
        "NOT", "NULL", "ON", "ONLY", "OPTIONAL", "OR", "ORDER", "PRIMARY", 
        "PROFILE", "PROJECT", "RDFGRAPH", "READ", "REL", "RENAME", "RETURN", 
        "ROLLBACK", "ROLLBACK_SKIP_CHECKPOINT", "SEQUENCE", "SET", "SHORTEST", 
        "START", "STARTS", "TABLE", "THEN", "TO", "TRANSACTION", "TRUE", 
        "TYPE", "UNION", "UNWIND", "USE", "WHEN", "WHERE", "WITH", "WRITE", 
        "XOR", "DECIMAL", "STAR", "L_SKIP", "INVALID_NOT_EQUAL", "MINUS", 
        "FACTORIAL", "COLON", "StringLiteral", "EscapedChar", "DecimalInteger", 
        "HexLetter", "HexDigit", "Digit", "NonZeroDigit", "NonZeroOctDigit", 
        "ZeroDigit", "RegularDecimalReal", "UnescapedSymbolicName", "IdentifierStart", 
        "IdentifierPart", "EscapedSymbolicName", "SP", "WHITESPACE", "CypherComment", 
        "Unknown"
    ];

    public static readonly modeNames = [
        "DEFAULT_MODE",
    ];

    public static readonly ruleNames = [
        "T__0", "T__1", "T__2", "T__3", "T__4", "T__5", "T__6", "T__7", 
        "T__8", "T__9", "T__10", "T__11", "T__12", "T__13", "T__14", "T__15", 
        "T__16", "T__17", "T__18", "T__19", "T__20", "T__21", "T__22", "T__23", 
        "T__24", "T__25", "T__26", "T__27", "T__28", "T__29", "T__30", "T__31", 
        "T__32", "T__33", "T__34", "T__35", "T__36", "T__37", "T__38", "T__39", 
        "T__40", "T__41", "T__42", "T__43", "T__44", "ADD", "ALL", "ALTER", 
        "AND", "AS", "ASC", "ASCENDING", "ATTACH", "BEGIN", "BY", "CALL", 
        "CASE", "CAST", "CHECKPOINT", "COLUMN", "COMMENT", "COMMIT", "COMMIT_SKIP_CHECKPOINT", 
        "CONTAINS", "COPY", "COUNT", "CREATE", "CYCLE", "DATABASE", "DBTYPE", 
        "DEFAULT", "DELETE", "DESC", "DESCENDING", "DETACH", "DISTINCT", 
        "DROP", "ELSE", "END", "ENDS", "EXISTS", "EXPLAIN", "EXPORT", "EXTENSION", 
        "FALSE", "FROM", "GLOB", "GRAPH", "GROUP", "HEADERS", "HINT", "IMPORT", 
        "IF", "IN", "INCREMENT", "INSTALL", "IS", "JOIN", "KEY", "LIMIT", 
        "LOAD", "MACRO", "MATCH", "MAXVALUE", "MERGE", "MINVALUE", "MULTI_JOIN", 
        "NO", "NODE", "NOT", "NULL", "ON", "ONLY", "OPTIONAL", "OR", "ORDER", 
        "PRIMARY", "PROFILE", "PROJECT", "RDFGRAPH", "READ", "REL", "RENAME", 
        "RETURN", "ROLLBACK", "ROLLBACK_SKIP_CHECKPOINT", "SEQUENCE", "SET", 
        "SHORTEST", "START", "STARTS", "TABLE", "THEN", "TO", "TRANSACTION", 
        "TRUE", "TYPE", "UNION", "UNWIND", "USE", "WHEN", "WHERE", "WITH", 
        "WRITE", "XOR", "DECIMAL", "STAR", "L_SKIP", "INVALID_NOT_EQUAL", 
        "MINUS", "FACTORIAL", "COLON", "StringLiteral", "EscapedChar", "DecimalInteger", 
        "HexLetter", "HexDigit", "Digit", "NonZeroDigit", "NonZeroOctDigit", 
        "ZeroDigit", "RegularDecimalReal", "UnescapedSymbolicName", "IdentifierStart", 
        "IdentifierPart", "EscapedSymbolicName", "SP", "WHITESPACE", "CypherComment", 
        "FF", "EscapedSymbolicName_0", "RS", "ID_Continue", "Comment_1", 
        "StringLiteral_1", "Comment_3", "Comment_2", "GS", "FS", "CR", "Sc", 
        "SPACE", "Pc", "TAB", "StringLiteral_0", "LF", "VT", "US", "ID_Start", 
        "Unknown",
    ];


    public constructor(input: antlr.CharStream) {
        super(input);
        this._interp = new antlr.LexerATNSimulator(this, CypherLexer._ATN, CypherLexer.decisionsToDFA, new antlr.PredictionContextCache());
    }

    public get grammarFileName(): string { return "Cypher.g4"; }

    public get literalNames(): (string | null)[] { return CypherLexer.literalNames; }
    public get symbolicNames(): (string | null)[] { return CypherLexer.symbolicNames; }
    public get ruleNames(): string[] { return CypherLexer.ruleNames; }

    public get serializedATN(): number[] { return CypherLexer._serializedATN; }

    public get channelNames(): string[] { return CypherLexer.channelNames; }

    public get modeNames(): string[] { return CypherLexer.modeNames; }

    public static readonly _serializedATN: number[] = [
        4,0,170,1360,6,-1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,
        5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,
        2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,
        7,19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,
        2,26,7,26,2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,
        7,32,2,33,7,33,2,34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,
        2,39,7,39,2,40,7,40,2,41,7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,
        7,45,2,46,7,46,2,47,7,47,2,48,7,48,2,49,7,49,2,50,7,50,2,51,7,51,
        2,52,7,52,2,53,7,53,2,54,7,54,2,55,7,55,2,56,7,56,2,57,7,57,2,58,
        7,58,2,59,7,59,2,60,7,60,2,61,7,61,2,62,7,62,2,63,7,63,2,64,7,64,
        2,65,7,65,2,66,7,66,2,67,7,67,2,68,7,68,2,69,7,69,2,70,7,70,2,71,
        7,71,2,72,7,72,2,73,7,73,2,74,7,74,2,75,7,75,2,76,7,76,2,77,7,77,
        2,78,7,78,2,79,7,79,2,80,7,80,2,81,7,81,2,82,7,82,2,83,7,83,2,84,
        7,84,2,85,7,85,2,86,7,86,2,87,7,87,2,88,7,88,2,89,7,89,2,90,7,90,
        2,91,7,91,2,92,7,92,2,93,7,93,2,94,7,94,2,95,7,95,2,96,7,96,2,97,
        7,97,2,98,7,98,2,99,7,99,2,100,7,100,2,101,7,101,2,102,7,102,2,103,
        7,103,2,104,7,104,2,105,7,105,2,106,7,106,2,107,7,107,2,108,7,108,
        2,109,7,109,2,110,7,110,2,111,7,111,2,112,7,112,2,113,7,113,2,114,
        7,114,2,115,7,115,2,116,7,116,2,117,7,117,2,118,7,118,2,119,7,119,
        2,120,7,120,2,121,7,121,2,122,7,122,2,123,7,123,2,124,7,124,2,125,
        7,125,2,126,7,126,2,127,7,127,2,128,7,128,2,129,7,129,2,130,7,130,
        2,131,7,131,2,132,7,132,2,133,7,133,2,134,7,134,2,135,7,135,2,136,
        7,136,2,137,7,137,2,138,7,138,2,139,7,139,2,140,7,140,2,141,7,141,
        2,142,7,142,2,143,7,143,2,144,7,144,2,145,7,145,2,146,7,146,2,147,
        7,147,2,148,7,148,2,149,7,149,2,150,7,150,2,151,7,151,2,152,7,152,
        2,153,7,153,2,154,7,154,2,155,7,155,2,156,7,156,2,157,7,157,2,158,
        7,158,2,159,7,159,2,160,7,160,2,161,7,161,2,162,7,162,2,163,7,163,
        2,164,7,164,2,165,7,165,2,166,7,166,2,167,7,167,2,168,7,168,2,169,
        7,169,2,170,7,170,2,171,7,171,2,172,7,172,2,173,7,173,2,174,7,174,
        2,175,7,175,2,176,7,176,2,177,7,177,2,178,7,178,2,179,7,179,2,180,
        7,180,2,181,7,181,2,182,7,182,2,183,7,183,2,184,7,184,2,185,7,185,
        2,186,7,186,2,187,7,187,2,188,7,188,2,189,7,189,1,0,1,0,1,1,1,1,
        1,2,1,2,1,3,1,3,1,4,1,4,1,5,1,5,1,6,1,6,1,7,1,7,1,8,1,8,1,9,1,9,
        1,10,1,10,1,11,1,11,1,11,1,12,1,12,1,12,1,13,1,13,1,14,1,14,1,14,
        1,15,1,15,1,16,1,16,1,16,1,17,1,17,1,18,1,18,1,18,1,19,1,19,1,19,
        1,20,1,20,1,21,1,21,1,22,1,22,1,23,1,23,1,24,1,24,1,24,1,25,1,25,
        1,26,1,26,1,27,1,27,1,28,1,28,1,29,1,29,1,30,1,30,1,31,1,31,1,32,
        1,32,1,33,1,33,1,34,1,34,1,35,1,35,1,36,1,36,1,37,1,37,1,38,1,38,
        1,39,1,39,1,40,1,40,1,41,1,41,1,42,1,42,1,43,1,43,1,44,1,44,1,45,
        1,45,1,45,1,45,1,46,1,46,1,46,1,46,1,47,1,47,1,47,1,47,1,47,1,47,
        1,48,1,48,1,48,1,48,1,49,1,49,1,49,1,50,1,50,1,50,1,50,1,51,1,51,
        1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,52,1,52,1,52,1,52,1,52,
        1,52,1,52,1,53,1,53,1,53,1,53,1,53,1,53,1,54,1,54,1,54,1,55,1,55,
        1,55,1,55,1,55,1,56,1,56,1,56,1,56,1,56,1,57,1,57,1,57,1,57,1,57,
        1,58,1,58,1,58,1,58,1,58,1,58,1,58,1,58,1,58,1,58,1,58,1,59,1,59,
        1,59,1,59,1,59,1,59,1,59,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,60,
        1,61,1,61,1,61,1,61,1,61,1,61,1,61,1,62,1,62,1,62,1,62,1,62,1,62,
        1,62,1,62,1,62,1,62,1,62,1,62,1,62,1,62,1,62,1,62,1,62,1,62,1,62,
        1,62,1,62,1,62,1,62,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,63,
        1,64,1,64,1,64,1,64,1,64,1,65,1,65,1,65,1,65,1,65,1,65,1,66,1,66,
        1,66,1,66,1,66,1,66,1,66,1,67,1,67,1,67,1,67,1,67,1,67,1,68,1,68,
        1,68,1,68,1,68,1,68,1,68,1,68,1,68,1,69,1,69,1,69,1,69,1,69,1,69,
        1,69,1,70,1,70,1,70,1,70,1,70,1,70,1,70,1,70,1,71,1,71,1,71,1,71,
        1,71,1,71,1,71,1,72,1,72,1,72,1,72,1,72,1,73,1,73,1,73,1,73,1,73,
        1,73,1,73,1,73,1,73,1,73,1,73,1,74,1,74,1,74,1,74,1,74,1,74,1,74,
        1,75,1,75,1,75,1,75,1,75,1,75,1,75,1,75,1,75,1,76,1,76,1,76,1,76,
        1,76,1,77,1,77,1,77,1,77,1,77,1,78,1,78,1,78,1,78,1,79,1,79,1,79,
        1,79,1,79,1,80,1,80,1,80,1,80,1,80,1,80,1,80,1,81,1,81,1,81,1,81,
        1,81,1,81,1,81,1,81,1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,83,1,83,
        1,83,1,83,1,83,1,83,1,83,1,83,1,83,1,83,1,84,1,84,1,84,1,84,1,84,
        1,84,1,85,1,85,1,85,1,85,1,85,1,86,1,86,1,86,1,86,1,86,1,87,1,87,
        1,87,1,87,1,87,1,87,1,88,1,88,1,88,1,88,1,88,1,88,1,89,1,89,1,89,
        1,89,1,89,1,89,1,89,1,89,1,90,1,90,1,90,1,90,1,90,1,91,1,91,1,91,
        1,91,1,91,1,91,1,91,1,92,1,92,1,92,1,93,1,93,1,93,1,94,1,94,1,94,
        1,94,1,94,1,94,1,94,1,94,1,94,1,94,1,95,1,95,1,95,1,95,1,95,1,95,
        1,95,1,95,1,96,1,96,1,96,1,97,1,97,1,97,1,97,1,97,1,98,1,98,1,98,
        1,98,1,99,1,99,1,99,1,99,1,99,1,99,1,100,1,100,1,100,1,100,1,100,
        1,101,1,101,1,101,1,101,1,101,1,101,1,102,1,102,1,102,1,102,1,102,
        1,102,1,103,1,103,1,103,1,103,1,103,1,103,1,103,1,103,1,103,1,104,
        1,104,1,104,1,104,1,104,1,104,1,105,1,105,1,105,1,105,1,105,1,105,
        1,105,1,105,1,105,1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,
        1,106,1,106,1,106,1,107,1,107,1,107,1,108,1,108,1,108,1,108,1,108,
        1,109,1,109,1,109,1,109,1,110,1,110,1,110,1,110,1,110,1,111,1,111,
        1,111,1,112,1,112,1,112,1,112,1,112,1,113,1,113,1,113,1,113,1,113,
        1,113,1,113,1,113,1,113,1,114,1,114,1,114,1,115,1,115,1,115,1,115,
        1,115,1,115,1,116,1,116,1,116,1,116,1,116,1,116,1,116,1,116,1,117,
        1,117,1,117,1,117,1,117,1,117,1,117,1,117,1,118,1,118,1,118,1,118,
        1,118,1,118,1,118,1,118,1,119,1,119,1,119,1,119,1,119,1,119,1,119,
        1,119,1,119,1,120,1,120,1,120,1,120,1,120,1,121,1,121,1,121,1,121,
        1,122,1,122,1,122,1,122,1,122,1,122,1,122,1,123,1,123,1,123,1,123,
        1,123,1,123,1,123,1,124,1,124,1,124,1,124,1,124,1,124,1,124,1,124,
        1,124,1,125,1,125,1,125,1,125,1,125,1,125,1,125,1,125,1,125,1,125,
        1,125,1,125,1,125,1,125,1,125,1,125,1,125,1,125,1,125,1,125,1,125,
        1,125,1,125,1,125,1,125,1,126,1,126,1,126,1,126,1,126,1,126,1,126,
        1,126,1,126,1,127,1,127,1,127,1,127,1,128,1,128,1,128,1,128,1,128,
        1,128,1,128,1,128,1,128,1,129,1,129,1,129,1,129,1,129,1,129,1,130,
        1,130,1,130,1,130,1,130,1,130,1,130,1,131,1,131,1,131,1,131,1,131,
        1,131,1,132,1,132,1,132,1,132,1,132,1,133,1,133,1,133,1,134,1,134,
        1,134,1,134,1,134,1,134,1,134,1,134,1,134,1,134,1,134,1,134,1,135,
        1,135,1,135,1,135,1,135,1,136,1,136,1,136,1,136,1,136,1,137,1,137,
        1,137,1,137,1,137,1,137,1,138,1,138,1,138,1,138,1,138,1,138,1,138,
        1,139,1,139,1,139,1,139,1,140,1,140,1,140,1,140,1,140,1,141,1,141,
        1,141,1,141,1,141,1,141,1,142,1,142,1,142,1,142,1,142,1,143,1,143,
        1,143,1,143,1,143,1,143,1,144,1,144,1,144,1,144,1,145,1,145,1,145,
        1,145,1,145,1,145,1,145,1,145,1,146,1,146,1,147,1,147,1,147,1,147,
        1,147,1,148,1,148,1,148,1,149,1,149,1,150,1,150,1,151,1,151,1,152,
        1,152,1,152,5,152,1164,8,152,10,152,12,152,1167,9,152,1,152,1,152,
        1,152,1,152,5,152,1173,8,152,10,152,12,152,1176,9,152,1,152,3,152,
        1179,8,152,1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,
        1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,3,153,1199,
        8,153,1,154,1,154,1,154,5,154,1204,8,154,10,154,12,154,1207,9,154,
        3,154,1209,8,154,1,155,3,155,1212,8,155,1,156,1,156,3,156,1216,8,
        156,1,157,1,157,3,157,1220,8,157,1,158,1,158,3,158,1224,8,158,1,
        159,1,159,1,160,1,160,1,161,5,161,1231,8,161,10,161,12,161,1234,
        9,161,1,161,1,161,4,161,1238,8,161,11,161,12,161,1239,1,162,1,162,
        5,162,1244,8,162,10,162,12,162,1247,9,162,1,163,1,163,3,163,1251,
        8,163,1,164,1,164,3,164,1255,8,164,1,165,1,165,5,165,1259,8,165,
        10,165,12,165,1262,9,165,1,165,4,165,1265,8,165,11,165,12,165,1266,
        1,166,4,166,1270,8,166,11,166,12,166,1271,1,167,1,167,1,167,1,167,
        1,167,1,167,1,167,1,167,1,167,1,167,1,167,1,167,3,167,1286,8,167,
        1,168,1,168,1,168,1,168,1,168,1,168,5,168,1294,8,168,10,168,12,168,
        1297,9,168,1,168,1,168,1,168,1,168,1,168,1,168,5,168,1305,8,168,
        10,168,12,168,1308,9,168,1,168,3,168,1311,8,168,1,168,1,168,3,168,
        1315,8,168,3,168,1317,8,168,1,169,1,169,1,170,1,170,1,171,1,171,
        1,172,1,172,1,173,1,173,1,174,1,174,1,175,1,175,1,176,1,176,1,177,
        1,177,1,178,1,178,1,179,1,179,1,180,1,180,1,181,1,181,1,182,1,182,
        1,183,1,183,1,184,1,184,1,185,1,185,1,186,1,186,1,187,1,187,1,188,
        1,188,1,189,1,189,0,0,190,1,1,3,2,5,3,7,4,9,5,11,6,13,7,15,8,17,
        9,19,10,21,11,23,12,25,13,27,14,29,15,31,16,33,17,35,18,37,19,39,
        20,41,21,43,22,45,23,47,24,49,25,51,26,53,27,55,28,57,29,59,30,61,
        31,63,32,65,33,67,34,69,35,71,36,73,37,75,38,77,39,79,40,81,41,83,
        42,85,43,87,44,89,45,91,46,93,47,95,48,97,49,99,50,101,51,103,52,
        105,53,107,54,109,55,111,56,113,57,115,58,117,59,119,60,121,61,123,
        62,125,63,127,64,129,65,131,66,133,67,135,68,137,69,139,70,141,71,
        143,72,145,73,147,74,149,75,151,76,153,77,155,78,157,79,159,80,161,
        81,163,82,165,83,167,84,169,85,171,86,173,87,175,88,177,89,179,90,
        181,91,183,92,185,93,187,94,189,95,191,96,193,97,195,98,197,99,199,
        100,201,101,203,102,205,103,207,104,209,105,211,106,213,107,215,
        108,217,109,219,110,221,111,223,112,225,113,227,114,229,115,231,
        116,233,117,235,118,237,119,239,120,241,121,243,122,245,123,247,
        124,249,125,251,126,253,127,255,128,257,129,259,130,261,131,263,
        132,265,133,267,134,269,135,271,136,273,137,275,138,277,139,279,
        140,281,141,283,142,285,143,287,144,289,145,291,146,293,147,295,
        148,297,149,299,150,301,151,303,152,305,153,307,154,309,155,311,
        156,313,157,315,158,317,159,319,160,321,161,323,162,325,163,327,
        164,329,165,331,166,333,167,335,168,337,169,339,0,341,0,343,0,345,
        0,347,0,349,0,351,0,353,0,355,0,357,0,359,0,361,0,363,0,365,0,367,
        0,369,0,371,0,373,0,375,0,377,0,379,170,1,0,48,2,0,65,65,97,97,2,
        0,68,68,100,100,2,0,76,76,108,108,2,0,84,84,116,116,2,0,69,69,101,
        101,2,0,82,82,114,114,2,0,78,78,110,110,2,0,83,83,115,115,2,0,67,
        67,99,99,2,0,73,73,105,105,2,0,71,71,103,103,2,0,72,72,104,104,2,
        0,66,66,98,98,2,0,89,89,121,121,2,0,75,75,107,107,2,0,80,80,112,
        112,2,0,79,79,111,111,2,0,85,85,117,117,2,0,77,77,109,109,2,0,70,
        70,102,102,2,0,88,88,120,120,2,0,74,74,106,106,2,0,86,86,118,118,
        2,0,81,81,113,113,2,0,87,87,119,119,13,0,34,34,39,39,66,66,70,70,
        78,78,82,82,84,84,92,92,98,98,102,102,110,110,114,114,116,116,2,
        0,65,70,97,102,8,0,160,160,5760,5760,6158,6158,8192,8202,8232,8233,
        8239,8239,8287,8287,12288,12288,1,0,12,12,1,0,96,96,1,0,30,30,768,
        0,48,57,65,90,95,95,97,122,170,170,181,181,183,183,186,186,192,214,
        216,246,248,705,710,721,736,740,748,748,750,750,768,884,886,887,
        890,893,895,895,902,906,908,908,910,929,931,1013,1015,1153,1155,
        1159,1162,1327,1329,1366,1369,1369,1376,1416,1425,1469,1471,1471,
        1473,1474,1476,1477,1479,1479,1488,1514,1519,1522,1552,1562,1568,
        1641,1646,1747,1749,1756,1759,1768,1770,1788,1791,1791,1808,1866,
        1869,1969,1984,2037,2042,2042,2045,2045,2048,2093,2112,2139,2144,
        2154,2160,2183,2185,2190,2200,2273,2275,2403,2406,2415,2417,2435,
        2437,2444,2447,2448,2451,2472,2474,2480,2482,2482,2486,2489,2492,
        2500,2503,2504,2507,2510,2519,2519,2524,2525,2527,2531,2534,2545,
        2556,2556,2558,2558,2561,2563,2565,2570,2575,2576,2579,2600,2602,
        2608,2610,2611,2613,2614,2616,2617,2620,2620,2622,2626,2631,2632,
        2635,2637,2641,2641,2649,2652,2654,2654,2662,2677,2689,2691,2693,
        2701,2703,2705,2707,2728,2730,2736,2738,2739,2741,2745,2748,2757,
        2759,2761,2763,2765,2768,2768,2784,2787,2790,2799,2809,2815,2817,
        2819,2821,2828,2831,2832,2835,2856,2858,2864,2866,2867,2869,2873,
        2876,2884,2887,2888,2891,2893,2901,2903,2908,2909,2911,2915,2918,
        2927,2929,2929,2946,2947,2949,2954,2958,2960,2962,2965,2969,2970,
        2972,2972,2974,2975,2979,2980,2984,2986,2990,3001,3006,3010,3014,
        3016,3018,3021,3024,3024,3031,3031,3046,3055,3072,3084,3086,3088,
        3090,3112,3114,3129,3132,3140,3142,3144,3146,3149,3157,3158,3160,
        3162,3165,3165,3168,3171,3174,3183,3200,3203,3205,3212,3214,3216,
        3218,3240,3242,3251,3253,3257,3260,3268,3270,3272,3274,3277,3285,
        3286,3293,3294,3296,3299,3302,3311,3313,3315,3328,3340,3342,3344,
        3346,3396,3398,3400,3402,3406,3412,3415,3423,3427,3430,3439,3450,
        3455,3457,3459,3461,3478,3482,3505,3507,3515,3517,3517,3520,3526,
        3530,3530,3535,3540,3542,3542,3544,3551,3558,3567,3570,3571,3585,
        3642,3648,3662,3664,3673,3713,3714,3716,3716,3718,3722,3724,3747,
        3749,3749,3751,3773,3776,3780,3782,3782,3784,3790,3792,3801,3804,
        3807,3840,3840,3864,3865,3872,3881,3893,3893,3895,3895,3897,3897,
        3902,3911,3913,3948,3953,3972,3974,3991,3993,4028,4038,4038,4096,
        4169,4176,4253,4256,4293,4295,4295,4301,4301,4304,4346,4348,4680,
        4682,4685,4688,4694,4696,4696,4698,4701,4704,4744,4746,4749,4752,
        4784,4786,4789,4792,4798,4800,4800,4802,4805,4808,4822,4824,4880,
        4882,4885,4888,4954,4957,4959,4969,4977,4992,5007,5024,5109,5112,
        5117,5121,5740,5743,5759,5761,5786,5792,5866,5870,5880,5888,5909,
        5919,5940,5952,5971,5984,5996,5998,6000,6002,6003,6016,6099,6103,
        6103,6108,6109,6112,6121,6155,6157,6159,6169,6176,6264,6272,6314,
        6320,6389,6400,6430,6432,6443,6448,6459,6470,6509,6512,6516,6528,
        6571,6576,6601,6608,6618,6656,6683,6688,6750,6752,6780,6783,6793,
        6800,6809,6823,6823,6832,6845,6847,6862,6912,6988,6992,7001,7019,
        7027,7040,7155,7168,7223,7232,7241,7245,7293,7296,7304,7312,7354,
        7357,7359,7376,7378,7380,7418,7424,7957,7960,7965,7968,8005,8008,
        8013,8016,8023,8025,8025,8027,8027,8029,8029,8031,8061,8064,8116,
        8118,8124,8126,8126,8130,8132,8134,8140,8144,8147,8150,8155,8160,
        8172,8178,8180,8182,8188,8255,8256,8276,8276,8305,8305,8319,8319,
        8336,8348,8400,8412,8417,8417,8421,8432,8450,8450,8455,8455,8458,
        8467,8469,8469,8472,8477,8484,8484,8486,8486,8488,8488,8490,8505,
        8508,8511,8517,8521,8526,8526,8544,8584,11264,11492,11499,11507,
        11520,11557,11559,11559,11565,11565,11568,11623,11631,11631,11647,
        11670,11680,11686,11688,11694,11696,11702,11704,11710,11712,11718,
        11720,11726,11728,11734,11736,11742,11744,11775,12293,12295,12321,
        12335,12337,12341,12344,12348,12353,12438,12441,12447,12449,12538,
        12540,12543,12549,12591,12593,12686,12704,12735,12784,12799,13312,
        19903,19968,42124,42192,42237,42240,42508,42512,42539,42560,42607,
        42612,42621,42623,42737,42775,42783,42786,42888,42891,42954,42960,
        42961,42963,42963,42965,42969,42994,43047,43052,43052,43072,43123,
        43136,43205,43216,43225,43232,43255,43259,43259,43261,43309,43312,
        43347,43360,43388,43392,43456,43471,43481,43488,43518,43520,43574,
        43584,43597,43600,43609,43616,43638,43642,43714,43739,43741,43744,
        43759,43762,43766,43777,43782,43785,43790,43793,43798,43808,43814,
        43816,43822,43824,43866,43868,43881,43888,44010,44012,44013,44016,
        44025,44032,55203,55216,55238,55243,55291,63744,64109,64112,64217,
        64256,64262,64275,64279,64285,64296,64298,64310,64312,64316,64318,
        64318,64320,64321,64323,64324,64326,64433,64467,64829,64848,64911,
        64914,64967,65008,65019,65024,65039,65056,65071,65075,65076,65101,
        65103,65136,65140,65142,65276,65296,65305,65313,65338,65343,65343,
        65345,65370,65382,65470,65474,65479,65482,65487,65490,65495,65498,
        65500,65536,65547,65549,65574,65576,65594,65596,65597,65599,65613,
        65616,65629,65664,65786,65856,65908,66045,66045,66176,66204,66208,
        66256,66272,66272,66304,66335,66349,66378,66384,66426,66432,66461,
        66464,66499,66504,66511,66513,66517,66560,66717,66720,66729,66736,
        66771,66776,66811,66816,66855,66864,66915,66928,66938,66940,66954,
        66956,66962,66964,66965,66967,66977,66979,66993,66995,67001,67003,
        67004,67072,67382,67392,67413,67424,67431,67456,67461,67463,67504,
        67506,67514,67584,67589,67592,67592,67594,67637,67639,67640,67644,
        67644,67647,67669,67680,67702,67712,67742,67808,67826,67828,67829,
        67840,67861,67872,67897,67968,68023,68030,68031,68096,68099,68101,
        68102,68108,68115,68117,68119,68121,68149,68152,68154,68159,68159,
        68192,68220,68224,68252,68288,68295,68297,68326,68352,68405,68416,
        68437,68448,68466,68480,68497,68608,68680,68736,68786,68800,68850,
        68864,68903,68912,68921,69248,69289,69291,69292,69296,69297,69373,
        69404,69415,69415,69424,69456,69488,69509,69552,69572,69600,69622,
        69632,69702,69734,69749,69759,69818,69826,69826,69840,69864,69872,
        69881,69888,69940,69942,69951,69956,69959,69968,70003,70006,70006,
        70016,70084,70089,70092,70094,70106,70108,70108,70144,70161,70163,
        70199,70206,70209,70272,70278,70280,70280,70282,70285,70287,70301,
        70303,70312,70320,70378,70384,70393,70400,70403,70405,70412,70415,
        70416,70419,70440,70442,70448,70450,70451,70453,70457,70459,70468,
        70471,70472,70475,70477,70480,70480,70487,70487,70493,70499,70502,
        70508,70512,70516,70656,70730,70736,70745,70750,70753,70784,70853,
        70855,70855,70864,70873,71040,71093,71096,71104,71128,71133,71168,
        71232,71236,71236,71248,71257,71296,71352,71360,71369,71424,71450,
        71453,71467,71472,71481,71488,71494,71680,71738,71840,71913,71935,
        71942,71945,71945,71948,71955,71957,71958,71960,71989,71991,71992,
        71995,72003,72016,72025,72096,72103,72106,72151,72154,72161,72163,
        72164,72192,72254,72263,72263,72272,72345,72349,72349,72368,72440,
        72704,72712,72714,72758,72760,72768,72784,72793,72818,72847,72850,
        72871,72873,72886,72960,72966,72968,72969,72971,73014,73018,73018,
        73020,73021,73023,73031,73040,73049,73056,73061,73063,73064,73066,
        73102,73104,73105,73107,73112,73120,73129,73440,73462,73472,73488,
        73490,73530,73534,73538,73552,73561,73648,73648,73728,74649,74752,
        74862,74880,75075,77712,77808,77824,78895,78912,78933,82944,83526,
        92160,92728,92736,92766,92768,92777,92784,92862,92864,92873,92880,
        92909,92912,92916,92928,92982,92992,92995,93008,93017,93027,93047,
        93053,93071,93760,93823,93952,94026,94031,94087,94095,94111,94176,
        94177,94179,94180,94192,94193,94208,100343,100352,101589,101632,
        101640,110576,110579,110581,110587,110589,110590,110592,110882,110898,
        110898,110928,110930,110933,110933,110948,110951,110960,111355,113664,
        113770,113776,113788,113792,113800,113808,113817,113821,113822,118528,
        118573,118576,118598,119141,119145,119149,119154,119163,119170,119173,
        119179,119210,119213,119362,119364,119808,119892,119894,119964,119966,
        119967,119970,119970,119973,119974,119977,119980,119982,119993,119995,
        119995,119997,120003,120005,120069,120071,120074,120077,120084,120086,
        120092,120094,120121,120123,120126,120128,120132,120134,120134,120138,
        120144,120146,120485,120488,120512,120514,120538,120540,120570,120572,
        120596,120598,120628,120630,120654,120656,120686,120688,120712,120714,
        120744,120746,120770,120772,120779,120782,120831,121344,121398,121403,
        121452,121461,121461,121476,121476,121499,121503,121505,121519,122624,
        122654,122661,122666,122880,122886,122888,122904,122907,122913,122915,
        122916,122918,122922,122928,122989,123023,123023,123136,123180,123184,
        123197,123200,123209,123214,123214,123536,123566,123584,123641,124112,
        124153,124896,124902,124904,124907,124909,124910,124912,124926,124928,
        125124,125136,125142,125184,125259,125264,125273,126464,126467,126469,
        126495,126497,126498,126500,126500,126503,126503,126505,126514,126516,
        126519,126521,126521,126523,126523,126530,126530,126535,126535,126537,
        126537,126539,126539,126541,126543,126545,126546,126548,126548,126551,
        126551,126553,126553,126555,126555,126557,126557,126559,126559,126561,
        126562,126564,126564,126567,126570,126572,126578,126580,126583,126585,
        126588,126590,126590,126592,126601,126603,126619,126625,126627,126629,
        126633,126635,126651,130032,130041,131072,173791,173824,177977,177984,
        178205,178208,183969,183984,191456,194560,195101,196608,201546,201552,
        205743,917760,917999,1,0,42,42,2,0,39,39,92,92,2,0,10,10,13,13,1,
        0,47,47,1,0,29,29,1,0,28,28,1,0,13,13,21,0,36,36,162,165,1423,1423,
        1547,1547,2046,2047,2546,2547,2555,2555,2801,2801,3065,3065,3647,
        3647,6107,6107,8352,8384,43064,43064,65020,65020,65129,65129,65284,
        65284,65504,65505,65509,65510,73693,73696,123647,123647,126128,126128,
        1,0,32,32,6,0,95,95,8255,8256,8276,8276,65075,65076,65101,65103,
        65343,65343,1,0,9,9,2,0,34,34,92,92,1,0,10,10,1,0,11,11,1,0,31,31,
        659,0,65,90,97,122,170,170,181,181,186,186,192,214,216,246,248,705,
        710,721,736,740,748,748,750,750,880,884,886,887,890,893,895,895,
        902,902,904,906,908,908,910,929,931,1013,1015,1153,1162,1327,1329,
        1366,1369,1369,1376,1416,1488,1514,1519,1522,1568,1610,1646,1647,
        1649,1747,1749,1749,1765,1766,1774,1775,1786,1788,1791,1791,1808,
        1808,1810,1839,1869,1957,1969,1969,1994,2026,2036,2037,2042,2042,
        2048,2069,2074,2074,2084,2084,2088,2088,2112,2136,2144,2154,2160,
        2183,2185,2190,2208,2249,2308,2361,2365,2365,2384,2384,2392,2401,
        2417,2432,2437,2444,2447,2448,2451,2472,2474,2480,2482,2482,2486,
        2489,2493,2493,2510,2510,2524,2525,2527,2529,2544,2545,2556,2556,
        2565,2570,2575,2576,2579,2600,2602,2608,2610,2611,2613,2614,2616,
        2617,2649,2652,2654,2654,2674,2676,2693,2701,2703,2705,2707,2728,
        2730,2736,2738,2739,2741,2745,2749,2749,2768,2768,2784,2785,2809,
        2809,2821,2828,2831,2832,2835,2856,2858,2864,2866,2867,2869,2873,
        2877,2877,2908,2909,2911,2913,2929,2929,2947,2947,2949,2954,2958,
        2960,2962,2965,2969,2970,2972,2972,2974,2975,2979,2980,2984,2986,
        2990,3001,3024,3024,3077,3084,3086,3088,3090,3112,3114,3129,3133,
        3133,3160,3162,3165,3165,3168,3169,3200,3200,3205,3212,3214,3216,
        3218,3240,3242,3251,3253,3257,3261,3261,3293,3294,3296,3297,3313,
        3314,3332,3340,3342,3344,3346,3386,3389,3389,3406,3406,3412,3414,
        3423,3425,3450,3455,3461,3478,3482,3505,3507,3515,3517,3517,3520,
        3526,3585,3632,3634,3635,3648,3654,3713,3714,3716,3716,3718,3722,
        3724,3747,3749,3749,3751,3760,3762,3763,3773,3773,3776,3780,3782,
        3782,3804,3807,3840,3840,3904,3911,3913,3948,3976,3980,4096,4138,
        4159,4159,4176,4181,4186,4189,4193,4193,4197,4198,4206,4208,4213,
        4225,4238,4238,4256,4293,4295,4295,4301,4301,4304,4346,4348,4680,
        4682,4685,4688,4694,4696,4696,4698,4701,4704,4744,4746,4749,4752,
        4784,4786,4789,4792,4798,4800,4800,4802,4805,4808,4822,4824,4880,
        4882,4885,4888,4954,4992,5007,5024,5109,5112,5117,5121,5740,5743,
        5759,5761,5786,5792,5866,5870,5880,5888,5905,5919,5937,5952,5969,
        5984,5996,5998,6000,6016,6067,6103,6103,6108,6108,6176,6264,6272,
        6312,6314,6314,6320,6389,6400,6430,6480,6509,6512,6516,6528,6571,
        6576,6601,6656,6678,6688,6740,6823,6823,6917,6963,6981,6988,7043,
        7072,7086,7087,7098,7141,7168,7203,7245,7247,7258,7293,7296,7304,
        7312,7354,7357,7359,7401,7404,7406,7411,7413,7414,7418,7418,7424,
        7615,7680,7957,7960,7965,7968,8005,8008,8013,8016,8023,8025,8025,
        8027,8027,8029,8029,8031,8061,8064,8116,8118,8124,8126,8126,8130,
        8132,8134,8140,8144,8147,8150,8155,8160,8172,8178,8180,8182,8188,
        8305,8305,8319,8319,8336,8348,8450,8450,8455,8455,8458,8467,8469,
        8469,8472,8477,8484,8484,8486,8486,8488,8488,8490,8505,8508,8511,
        8517,8521,8526,8526,8544,8584,11264,11492,11499,11502,11506,11507,
        11520,11557,11559,11559,11565,11565,11568,11623,11631,11631,11648,
        11670,11680,11686,11688,11694,11696,11702,11704,11710,11712,11718,
        11720,11726,11728,11734,11736,11742,12293,12295,12321,12329,12337,
        12341,12344,12348,12353,12438,12443,12447,12449,12538,12540,12543,
        12549,12591,12593,12686,12704,12735,12784,12799,13312,19903,19968,
        42124,42192,42237,42240,42508,42512,42527,42538,42539,42560,42606,
        42623,42653,42656,42735,42775,42783,42786,42888,42891,42954,42960,
        42961,42963,42963,42965,42969,42994,43009,43011,43013,43015,43018,
        43020,43042,43072,43123,43138,43187,43250,43255,43259,43259,43261,
        43262,43274,43301,43312,43334,43360,43388,43396,43442,43471,43471,
        43488,43492,43494,43503,43514,43518,43520,43560,43584,43586,43588,
        43595,43616,43638,43642,43642,43646,43695,43697,43697,43701,43702,
        43705,43709,43712,43712,43714,43714,43739,43741,43744,43754,43762,
        43764,43777,43782,43785,43790,43793,43798,43808,43814,43816,43822,
        43824,43866,43868,43881,43888,44002,44032,55203,55216,55238,55243,
        55291,63744,64109,64112,64217,64256,64262,64275,64279,64285,64285,
        64287,64296,64298,64310,64312,64316,64318,64318,64320,64321,64323,
        64324,64326,64433,64467,64829,64848,64911,64914,64967,65008,65019,
        65136,65140,65142,65276,65313,65338,65345,65370,65382,65470,65474,
        65479,65482,65487,65490,65495,65498,65500,65536,65547,65549,65574,
        65576,65594,65596,65597,65599,65613,65616,65629,65664,65786,65856,
        65908,66176,66204,66208,66256,66304,66335,66349,66378,66384,66421,
        66432,66461,66464,66499,66504,66511,66513,66517,66560,66717,66736,
        66771,66776,66811,66816,66855,66864,66915,66928,66938,66940,66954,
        66956,66962,66964,66965,66967,66977,66979,66993,66995,67001,67003,
        67004,67072,67382,67392,67413,67424,67431,67456,67461,67463,67504,
        67506,67514,67584,67589,67592,67592,67594,67637,67639,67640,67644,
        67644,67647,67669,67680,67702,67712,67742,67808,67826,67828,67829,
        67840,67861,67872,67897,67968,68023,68030,68031,68096,68096,68112,
        68115,68117,68119,68121,68149,68192,68220,68224,68252,68288,68295,
        68297,68324,68352,68405,68416,68437,68448,68466,68480,68497,68608,
        68680,68736,68786,68800,68850,68864,68899,69248,69289,69296,69297,
        69376,69404,69415,69415,69424,69445,69488,69505,69552,69572,69600,
        69622,69635,69687,69745,69746,69749,69749,69763,69807,69840,69864,
        69891,69926,69956,69956,69959,69959,69968,70002,70006,70006,70019,
        70066,70081,70084,70106,70106,70108,70108,70144,70161,70163,70187,
        70207,70208,70272,70278,70280,70280,70282,70285,70287,70301,70303,
        70312,70320,70366,70405,70412,70415,70416,70419,70440,70442,70448,
        70450,70451,70453,70457,70461,70461,70480,70480,70493,70497,70656,
        70708,70727,70730,70751,70753,70784,70831,70852,70853,70855,70855,
        71040,71086,71128,71131,71168,71215,71236,71236,71296,71338,71352,
        71352,71424,71450,71488,71494,71680,71723,71840,71903,71935,71942,
        71945,71945,71948,71955,71957,71958,71960,71983,71999,71999,72001,
        72001,72096,72103,72106,72144,72161,72161,72163,72163,72192,72192,
        72203,72242,72250,72250,72272,72272,72284,72329,72349,72349,72368,
        72440,72704,72712,72714,72750,72768,72768,72818,72847,72960,72966,
        72968,72969,72971,73008,73030,73030,73056,73061,73063,73064,73066,
        73097,73112,73112,73440,73458,73474,73474,73476,73488,73490,73523,
        73648,73648,73728,74649,74752,74862,74880,75075,77712,77808,77824,
        78895,78913,78918,82944,83526,92160,92728,92736,92766,92784,92862,
        92880,92909,92928,92975,92992,92995,93027,93047,93053,93071,93760,
        93823,93952,94026,94032,94032,94099,94111,94176,94177,94179,94179,
        94208,100343,100352,101589,101632,101640,110576,110579,110581,110587,
        110589,110590,110592,110882,110898,110898,110928,110930,110933,110933,
        110948,110951,110960,111355,113664,113770,113776,113788,113792,113800,
        113808,113817,119808,119892,119894,119964,119966,119967,119970,119970,
        119973,119974,119977,119980,119982,119993,119995,119995,119997,120003,
        120005,120069,120071,120074,120077,120084,120086,120092,120094,120121,
        120123,120126,120128,120132,120134,120134,120138,120144,120146,120485,
        120488,120512,120514,120538,120540,120570,120572,120596,120598,120628,
        120630,120654,120656,120686,120688,120712,120714,120744,120746,120770,
        120772,120779,122624,122654,122661,122666,122928,122989,123136,123180,
        123191,123197,123214,123214,123536,123565,123584,123627,124112,124139,
        124896,124902,124904,124907,124909,124910,124912,124926,124928,125124,
        125184,125251,125259,125259,126464,126467,126469,126495,126497,126498,
        126500,126500,126503,126503,126505,126514,126516,126519,126521,126521,
        126523,126523,126530,126530,126535,126535,126537,126537,126539,126539,
        126541,126543,126545,126546,126548,126548,126551,126551,126553,126553,
        126555,126555,126557,126557,126559,126559,126561,126562,126564,126564,
        126567,126570,126572,126578,126580,126583,126585,126588,126590,126590,
        126592,126601,126603,126619,126625,126627,126629,126633,126635,126651,
        131072,173791,173824,177977,177984,178205,178208,183969,183984,191456,
        194560,195101,196608,201546,201552,205743,1376,0,1,1,0,0,0,0,3,1,
        0,0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,0,11,1,0,0,0,0,13,1,0,
        0,0,0,15,1,0,0,0,0,17,1,0,0,0,0,19,1,0,0,0,0,21,1,0,0,0,0,23,1,0,
        0,0,0,25,1,0,0,0,0,27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,33,1,0,
        0,0,0,35,1,0,0,0,0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,0,
        0,0,0,45,1,0,0,0,0,47,1,0,0,0,0,49,1,0,0,0,0,51,1,0,0,0,0,53,1,0,
        0,0,0,55,1,0,0,0,0,57,1,0,0,0,0,59,1,0,0,0,0,61,1,0,0,0,0,63,1,0,
        0,0,0,65,1,0,0,0,0,67,1,0,0,0,0,69,1,0,0,0,0,71,1,0,0,0,0,73,1,0,
        0,0,0,75,1,0,0,0,0,77,1,0,0,0,0,79,1,0,0,0,0,81,1,0,0,0,0,83,1,0,
        0,0,0,85,1,0,0,0,0,87,1,0,0,0,0,89,1,0,0,0,0,91,1,0,0,0,0,93,1,0,
        0,0,0,95,1,0,0,0,0,97,1,0,0,0,0,99,1,0,0,0,0,101,1,0,0,0,0,103,1,
        0,0,0,0,105,1,0,0,0,0,107,1,0,0,0,0,109,1,0,0,0,0,111,1,0,0,0,0,
        113,1,0,0,0,0,115,1,0,0,0,0,117,1,0,0,0,0,119,1,0,0,0,0,121,1,0,
        0,0,0,123,1,0,0,0,0,125,1,0,0,0,0,127,1,0,0,0,0,129,1,0,0,0,0,131,
        1,0,0,0,0,133,1,0,0,0,0,135,1,0,0,0,0,137,1,0,0,0,0,139,1,0,0,0,
        0,141,1,0,0,0,0,143,1,0,0,0,0,145,1,0,0,0,0,147,1,0,0,0,0,149,1,
        0,0,0,0,151,1,0,0,0,0,153,1,0,0,0,0,155,1,0,0,0,0,157,1,0,0,0,0,
        159,1,0,0,0,0,161,1,0,0,0,0,163,1,0,0,0,0,165,1,0,0,0,0,167,1,0,
        0,0,0,169,1,0,0,0,0,171,1,0,0,0,0,173,1,0,0,0,0,175,1,0,0,0,0,177,
        1,0,0,0,0,179,1,0,0,0,0,181,1,0,0,0,0,183,1,0,0,0,0,185,1,0,0,0,
        0,187,1,0,0,0,0,189,1,0,0,0,0,191,1,0,0,0,0,193,1,0,0,0,0,195,1,
        0,0,0,0,197,1,0,0,0,0,199,1,0,0,0,0,201,1,0,0,0,0,203,1,0,0,0,0,
        205,1,0,0,0,0,207,1,0,0,0,0,209,1,0,0,0,0,211,1,0,0,0,0,213,1,0,
        0,0,0,215,1,0,0,0,0,217,1,0,0,0,0,219,1,0,0,0,0,221,1,0,0,0,0,223,
        1,0,0,0,0,225,1,0,0,0,0,227,1,0,0,0,0,229,1,0,0,0,0,231,1,0,0,0,
        0,233,1,0,0,0,0,235,1,0,0,0,0,237,1,0,0,0,0,239,1,0,0,0,0,241,1,
        0,0,0,0,243,1,0,0,0,0,245,1,0,0,0,0,247,1,0,0,0,0,249,1,0,0,0,0,
        251,1,0,0,0,0,253,1,0,0,0,0,255,1,0,0,0,0,257,1,0,0,0,0,259,1,0,
        0,0,0,261,1,0,0,0,0,263,1,0,0,0,0,265,1,0,0,0,0,267,1,0,0,0,0,269,
        1,0,0,0,0,271,1,0,0,0,0,273,1,0,0,0,0,275,1,0,0,0,0,277,1,0,0,0,
        0,279,1,0,0,0,0,281,1,0,0,0,0,283,1,0,0,0,0,285,1,0,0,0,0,287,1,
        0,0,0,0,289,1,0,0,0,0,291,1,0,0,0,0,293,1,0,0,0,0,295,1,0,0,0,0,
        297,1,0,0,0,0,299,1,0,0,0,0,301,1,0,0,0,0,303,1,0,0,0,0,305,1,0,
        0,0,0,307,1,0,0,0,0,309,1,0,0,0,0,311,1,0,0,0,0,313,1,0,0,0,0,315,
        1,0,0,0,0,317,1,0,0,0,0,319,1,0,0,0,0,321,1,0,0,0,0,323,1,0,0,0,
        0,325,1,0,0,0,0,327,1,0,0,0,0,329,1,0,0,0,0,331,1,0,0,0,0,333,1,
        0,0,0,0,335,1,0,0,0,0,337,1,0,0,0,0,379,1,0,0,0,1,381,1,0,0,0,3,
        383,1,0,0,0,5,385,1,0,0,0,7,387,1,0,0,0,9,389,1,0,0,0,11,391,1,0,
        0,0,13,393,1,0,0,0,15,395,1,0,0,0,17,397,1,0,0,0,19,399,1,0,0,0,
        21,401,1,0,0,0,23,403,1,0,0,0,25,406,1,0,0,0,27,409,1,0,0,0,29,411,
        1,0,0,0,31,414,1,0,0,0,33,416,1,0,0,0,35,419,1,0,0,0,37,421,1,0,
        0,0,39,424,1,0,0,0,41,427,1,0,0,0,43,429,1,0,0,0,45,431,1,0,0,0,
        47,433,1,0,0,0,49,435,1,0,0,0,51,438,1,0,0,0,53,440,1,0,0,0,55,442,
        1,0,0,0,57,444,1,0,0,0,59,446,1,0,0,0,61,448,1,0,0,0,63,450,1,0,
        0,0,65,452,1,0,0,0,67,454,1,0,0,0,69,456,1,0,0,0,71,458,1,0,0,0,
        73,460,1,0,0,0,75,462,1,0,0,0,77,464,1,0,0,0,79,466,1,0,0,0,81,468,
        1,0,0,0,83,470,1,0,0,0,85,472,1,0,0,0,87,474,1,0,0,0,89,476,1,0,
        0,0,91,478,1,0,0,0,93,482,1,0,0,0,95,486,1,0,0,0,97,492,1,0,0,0,
        99,496,1,0,0,0,101,499,1,0,0,0,103,503,1,0,0,0,105,513,1,0,0,0,107,
        520,1,0,0,0,109,526,1,0,0,0,111,529,1,0,0,0,113,534,1,0,0,0,115,
        539,1,0,0,0,117,544,1,0,0,0,119,555,1,0,0,0,121,562,1,0,0,0,123,
        570,1,0,0,0,125,577,1,0,0,0,127,600,1,0,0,0,129,609,1,0,0,0,131,
        614,1,0,0,0,133,620,1,0,0,0,135,627,1,0,0,0,137,633,1,0,0,0,139,
        642,1,0,0,0,141,649,1,0,0,0,143,657,1,0,0,0,145,664,1,0,0,0,147,
        669,1,0,0,0,149,680,1,0,0,0,151,687,1,0,0,0,153,696,1,0,0,0,155,
        701,1,0,0,0,157,706,1,0,0,0,159,710,1,0,0,0,161,715,1,0,0,0,163,
        722,1,0,0,0,165,730,1,0,0,0,167,737,1,0,0,0,169,747,1,0,0,0,171,
        753,1,0,0,0,173,758,1,0,0,0,175,763,1,0,0,0,177,769,1,0,0,0,179,
        775,1,0,0,0,181,783,1,0,0,0,183,788,1,0,0,0,185,795,1,0,0,0,187,
        798,1,0,0,0,189,801,1,0,0,0,191,811,1,0,0,0,193,819,1,0,0,0,195,
        822,1,0,0,0,197,827,1,0,0,0,199,831,1,0,0,0,201,837,1,0,0,0,203,
        842,1,0,0,0,205,848,1,0,0,0,207,854,1,0,0,0,209,863,1,0,0,0,211,
        869,1,0,0,0,213,878,1,0,0,0,215,889,1,0,0,0,217,892,1,0,0,0,219,
        897,1,0,0,0,221,901,1,0,0,0,223,906,1,0,0,0,225,909,1,0,0,0,227,
        914,1,0,0,0,229,923,1,0,0,0,231,926,1,0,0,0,233,932,1,0,0,0,235,
        940,1,0,0,0,237,948,1,0,0,0,239,956,1,0,0,0,241,965,1,0,0,0,243,
        970,1,0,0,0,245,974,1,0,0,0,247,981,1,0,0,0,249,988,1,0,0,0,251,
        997,1,0,0,0,253,1022,1,0,0,0,255,1031,1,0,0,0,257,1035,1,0,0,0,259,
        1044,1,0,0,0,261,1050,1,0,0,0,263,1057,1,0,0,0,265,1063,1,0,0,0,
        267,1068,1,0,0,0,269,1071,1,0,0,0,271,1083,1,0,0,0,273,1088,1,0,
        0,0,275,1093,1,0,0,0,277,1099,1,0,0,0,279,1106,1,0,0,0,281,1110,
        1,0,0,0,283,1115,1,0,0,0,285,1121,1,0,0,0,287,1126,1,0,0,0,289,1132,
        1,0,0,0,291,1136,1,0,0,0,293,1144,1,0,0,0,295,1146,1,0,0,0,297,1151,
        1,0,0,0,299,1154,1,0,0,0,301,1156,1,0,0,0,303,1158,1,0,0,0,305,1178,
        1,0,0,0,307,1180,1,0,0,0,309,1208,1,0,0,0,311,1211,1,0,0,0,313,1215,
        1,0,0,0,315,1219,1,0,0,0,317,1223,1,0,0,0,319,1225,1,0,0,0,321,1227,
        1,0,0,0,323,1232,1,0,0,0,325,1241,1,0,0,0,327,1250,1,0,0,0,329,1254,
        1,0,0,0,331,1264,1,0,0,0,333,1269,1,0,0,0,335,1285,1,0,0,0,337,1316,
        1,0,0,0,339,1318,1,0,0,0,341,1320,1,0,0,0,343,1322,1,0,0,0,345,1324,
        1,0,0,0,347,1326,1,0,0,0,349,1328,1,0,0,0,351,1330,1,0,0,0,353,1332,
        1,0,0,0,355,1334,1,0,0,0,357,1336,1,0,0,0,359,1338,1,0,0,0,361,1340,
        1,0,0,0,363,1342,1,0,0,0,365,1344,1,0,0,0,367,1346,1,0,0,0,369,1348,
        1,0,0,0,371,1350,1,0,0,0,373,1352,1,0,0,0,375,1354,1,0,0,0,377,1356,
        1,0,0,0,379,1358,1,0,0,0,381,382,5,59,0,0,382,2,1,0,0,0,383,384,
        5,40,0,0,384,4,1,0,0,0,385,386,5,44,0,0,386,6,1,0,0,0,387,388,5,
        41,0,0,388,8,1,0,0,0,389,390,5,46,0,0,390,10,1,0,0,0,391,392,5,61,
        0,0,392,12,1,0,0,0,393,394,5,91,0,0,394,14,1,0,0,0,395,396,5,93,
        0,0,396,16,1,0,0,0,397,398,5,123,0,0,398,18,1,0,0,0,399,400,5,125,
        0,0,400,20,1,0,0,0,401,402,5,124,0,0,402,22,1,0,0,0,403,404,5,46,
        0,0,404,405,5,46,0,0,405,24,1,0,0,0,406,407,5,60,0,0,407,408,5,62,
        0,0,408,26,1,0,0,0,409,410,5,60,0,0,410,28,1,0,0,0,411,412,5,60,
        0,0,412,413,5,61,0,0,413,30,1,0,0,0,414,415,5,62,0,0,415,32,1,0,
        0,0,416,417,5,62,0,0,417,418,5,61,0,0,418,34,1,0,0,0,419,420,5,38,
        0,0,420,36,1,0,0,0,421,422,5,62,0,0,422,423,5,62,0,0,423,38,1,0,
        0,0,424,425,5,60,0,0,425,426,5,60,0,0,426,40,1,0,0,0,427,428,5,43,
        0,0,428,42,1,0,0,0,429,430,5,47,0,0,430,44,1,0,0,0,431,432,5,37,
        0,0,432,46,1,0,0,0,433,434,5,94,0,0,434,48,1,0,0,0,435,436,5,61,
        0,0,436,437,5,126,0,0,437,50,1,0,0,0,438,439,5,36,0,0,439,52,1,0,
        0,0,440,441,5,10216,0,0,441,54,1,0,0,0,442,443,5,12296,0,0,443,56,
        1,0,0,0,444,445,5,65124,0,0,445,58,1,0,0,0,446,447,5,65308,0,0,447,
        60,1,0,0,0,448,449,5,10217,0,0,449,62,1,0,0,0,450,451,5,12297,0,
        0,451,64,1,0,0,0,452,453,5,65125,0,0,453,66,1,0,0,0,454,455,5,65310,
        0,0,455,68,1,0,0,0,456,457,5,173,0,0,457,70,1,0,0,0,458,459,5,8208,
        0,0,459,72,1,0,0,0,460,461,5,8209,0,0,461,74,1,0,0,0,462,463,5,8210,
        0,0,463,76,1,0,0,0,464,465,5,8211,0,0,465,78,1,0,0,0,466,467,5,8212,
        0,0,467,80,1,0,0,0,468,469,5,8213,0,0,469,82,1,0,0,0,470,471,5,8722,
        0,0,471,84,1,0,0,0,472,473,5,65112,0,0,473,86,1,0,0,0,474,475,5,
        65123,0,0,475,88,1,0,0,0,476,477,5,65293,0,0,477,90,1,0,0,0,478,
        479,7,0,0,0,479,480,7,1,0,0,480,481,7,1,0,0,481,92,1,0,0,0,482,483,
        7,0,0,0,483,484,7,2,0,0,484,485,7,2,0,0,485,94,1,0,0,0,486,487,7,
        0,0,0,487,488,7,2,0,0,488,489,7,3,0,0,489,490,7,4,0,0,490,491,7,
        5,0,0,491,96,1,0,0,0,492,493,7,0,0,0,493,494,7,6,0,0,494,495,7,1,
        0,0,495,98,1,0,0,0,496,497,7,0,0,0,497,498,7,7,0,0,498,100,1,0,0,
        0,499,500,7,0,0,0,500,501,7,7,0,0,501,502,7,8,0,0,502,102,1,0,0,
        0,503,504,7,0,0,0,504,505,7,7,0,0,505,506,7,8,0,0,506,507,7,4,0,
        0,507,508,7,6,0,0,508,509,7,1,0,0,509,510,7,9,0,0,510,511,7,6,0,
        0,511,512,7,10,0,0,512,104,1,0,0,0,513,514,7,0,0,0,514,515,7,3,0,
        0,515,516,7,3,0,0,516,517,7,0,0,0,517,518,7,8,0,0,518,519,7,11,0,
        0,519,106,1,0,0,0,520,521,7,12,0,0,521,522,7,4,0,0,522,523,7,10,
        0,0,523,524,7,9,0,0,524,525,7,6,0,0,525,108,1,0,0,0,526,527,7,12,
        0,0,527,528,7,13,0,0,528,110,1,0,0,0,529,530,7,8,0,0,530,531,7,0,
        0,0,531,532,7,2,0,0,532,533,7,2,0,0,533,112,1,0,0,0,534,535,7,8,
        0,0,535,536,7,0,0,0,536,537,7,7,0,0,537,538,7,4,0,0,538,114,1,0,
        0,0,539,540,7,8,0,0,540,541,7,0,0,0,541,542,7,7,0,0,542,543,7,3,
        0,0,543,116,1,0,0,0,544,545,7,8,0,0,545,546,7,11,0,0,546,547,7,4,
        0,0,547,548,7,8,0,0,548,549,7,14,0,0,549,550,7,15,0,0,550,551,7,
        16,0,0,551,552,7,9,0,0,552,553,7,6,0,0,553,554,7,3,0,0,554,118,1,
        0,0,0,555,556,7,8,0,0,556,557,7,16,0,0,557,558,7,2,0,0,558,559,7,
        17,0,0,559,560,7,18,0,0,560,561,7,6,0,0,561,120,1,0,0,0,562,563,
        7,8,0,0,563,564,7,16,0,0,564,565,7,18,0,0,565,566,7,18,0,0,566,567,
        7,4,0,0,567,568,7,6,0,0,568,569,7,3,0,0,569,122,1,0,0,0,570,571,
        7,8,0,0,571,572,7,16,0,0,572,573,7,18,0,0,573,574,7,18,0,0,574,575,
        7,9,0,0,575,576,7,3,0,0,576,124,1,0,0,0,577,578,7,8,0,0,578,579,
        7,16,0,0,579,580,7,18,0,0,580,581,7,18,0,0,581,582,7,9,0,0,582,583,
        7,3,0,0,583,584,5,95,0,0,584,585,7,7,0,0,585,586,7,14,0,0,586,587,
        7,9,0,0,587,588,7,15,0,0,588,589,5,95,0,0,589,590,7,8,0,0,590,591,
        7,11,0,0,591,592,7,4,0,0,592,593,7,8,0,0,593,594,7,14,0,0,594,595,
        7,15,0,0,595,596,7,16,0,0,596,597,7,9,0,0,597,598,7,6,0,0,598,599,
        7,3,0,0,599,126,1,0,0,0,600,601,7,8,0,0,601,602,7,16,0,0,602,603,
        7,6,0,0,603,604,7,3,0,0,604,605,7,0,0,0,605,606,7,9,0,0,606,607,
        7,6,0,0,607,608,7,7,0,0,608,128,1,0,0,0,609,610,7,8,0,0,610,611,
        7,16,0,0,611,612,7,15,0,0,612,613,7,13,0,0,613,130,1,0,0,0,614,615,
        7,8,0,0,615,616,7,16,0,0,616,617,7,17,0,0,617,618,7,6,0,0,618,619,
        7,3,0,0,619,132,1,0,0,0,620,621,7,8,0,0,621,622,7,5,0,0,622,623,
        7,4,0,0,623,624,7,0,0,0,624,625,7,3,0,0,625,626,7,4,0,0,626,134,
        1,0,0,0,627,628,7,8,0,0,628,629,7,13,0,0,629,630,7,8,0,0,630,631,
        7,2,0,0,631,632,7,4,0,0,632,136,1,0,0,0,633,634,7,1,0,0,634,635,
        7,0,0,0,635,636,7,3,0,0,636,637,7,0,0,0,637,638,7,12,0,0,638,639,
        7,0,0,0,639,640,7,7,0,0,640,641,7,4,0,0,641,138,1,0,0,0,642,643,
        7,1,0,0,643,644,7,12,0,0,644,645,7,3,0,0,645,646,7,13,0,0,646,647,
        7,15,0,0,647,648,7,4,0,0,648,140,1,0,0,0,649,650,7,1,0,0,650,651,
        7,4,0,0,651,652,7,19,0,0,652,653,7,0,0,0,653,654,7,17,0,0,654,655,
        7,2,0,0,655,656,7,3,0,0,656,142,1,0,0,0,657,658,7,1,0,0,658,659,
        7,4,0,0,659,660,7,2,0,0,660,661,7,4,0,0,661,662,7,3,0,0,662,663,
        7,4,0,0,663,144,1,0,0,0,664,665,7,1,0,0,665,666,7,4,0,0,666,667,
        7,7,0,0,667,668,7,8,0,0,668,146,1,0,0,0,669,670,7,1,0,0,670,671,
        7,4,0,0,671,672,7,7,0,0,672,673,7,8,0,0,673,674,7,4,0,0,674,675,
        7,6,0,0,675,676,7,1,0,0,676,677,7,9,0,0,677,678,7,6,0,0,678,679,
        7,10,0,0,679,148,1,0,0,0,680,681,7,1,0,0,681,682,7,4,0,0,682,683,
        7,3,0,0,683,684,7,0,0,0,684,685,7,8,0,0,685,686,7,11,0,0,686,150,
        1,0,0,0,687,688,7,1,0,0,688,689,7,9,0,0,689,690,7,7,0,0,690,691,
        7,3,0,0,691,692,7,9,0,0,692,693,7,6,0,0,693,694,7,8,0,0,694,695,
        7,3,0,0,695,152,1,0,0,0,696,697,7,1,0,0,697,698,7,5,0,0,698,699,
        7,16,0,0,699,700,7,15,0,0,700,154,1,0,0,0,701,702,7,4,0,0,702,703,
        7,2,0,0,703,704,7,7,0,0,704,705,7,4,0,0,705,156,1,0,0,0,706,707,
        7,4,0,0,707,708,7,6,0,0,708,709,7,1,0,0,709,158,1,0,0,0,710,711,
        7,4,0,0,711,712,7,6,0,0,712,713,7,1,0,0,713,714,7,7,0,0,714,160,
        1,0,0,0,715,716,7,4,0,0,716,717,7,20,0,0,717,718,7,9,0,0,718,719,
        7,7,0,0,719,720,7,3,0,0,720,721,7,7,0,0,721,162,1,0,0,0,722,723,
        7,4,0,0,723,724,7,20,0,0,724,725,7,15,0,0,725,726,7,2,0,0,726,727,
        7,0,0,0,727,728,7,9,0,0,728,729,7,6,0,0,729,164,1,0,0,0,730,731,
        7,4,0,0,731,732,7,20,0,0,732,733,7,15,0,0,733,734,7,16,0,0,734,735,
        7,5,0,0,735,736,7,3,0,0,736,166,1,0,0,0,737,738,7,4,0,0,738,739,
        7,20,0,0,739,740,7,3,0,0,740,741,7,4,0,0,741,742,7,6,0,0,742,743,
        7,7,0,0,743,744,7,9,0,0,744,745,7,16,0,0,745,746,7,6,0,0,746,168,
        1,0,0,0,747,748,7,19,0,0,748,749,7,0,0,0,749,750,7,2,0,0,750,751,
        7,7,0,0,751,752,7,4,0,0,752,170,1,0,0,0,753,754,7,19,0,0,754,755,
        7,5,0,0,755,756,7,16,0,0,756,757,7,18,0,0,757,172,1,0,0,0,758,759,
        7,10,0,0,759,760,7,2,0,0,760,761,7,16,0,0,761,762,7,12,0,0,762,174,
        1,0,0,0,763,764,7,10,0,0,764,765,7,5,0,0,765,766,7,0,0,0,766,767,
        7,15,0,0,767,768,7,11,0,0,768,176,1,0,0,0,769,770,7,10,0,0,770,771,
        7,5,0,0,771,772,7,16,0,0,772,773,7,17,0,0,773,774,7,15,0,0,774,178,
        1,0,0,0,775,776,7,11,0,0,776,777,7,4,0,0,777,778,7,0,0,0,778,779,
        7,1,0,0,779,780,7,4,0,0,780,781,7,5,0,0,781,782,7,7,0,0,782,180,
        1,0,0,0,783,784,7,11,0,0,784,785,7,9,0,0,785,786,7,6,0,0,786,787,
        7,3,0,0,787,182,1,0,0,0,788,789,7,9,0,0,789,790,7,18,0,0,790,791,
        7,15,0,0,791,792,7,16,0,0,792,793,7,5,0,0,793,794,7,3,0,0,794,184,
        1,0,0,0,795,796,7,9,0,0,796,797,7,19,0,0,797,186,1,0,0,0,798,799,
        7,9,0,0,799,800,7,6,0,0,800,188,1,0,0,0,801,802,7,9,0,0,802,803,
        7,6,0,0,803,804,7,8,0,0,804,805,7,5,0,0,805,806,7,4,0,0,806,807,
        7,18,0,0,807,808,7,4,0,0,808,809,7,6,0,0,809,810,7,3,0,0,810,190,
        1,0,0,0,811,812,7,9,0,0,812,813,7,6,0,0,813,814,7,7,0,0,814,815,
        7,3,0,0,815,816,7,0,0,0,816,817,7,2,0,0,817,818,7,2,0,0,818,192,
        1,0,0,0,819,820,7,9,0,0,820,821,7,7,0,0,821,194,1,0,0,0,822,823,
        7,21,0,0,823,824,7,16,0,0,824,825,7,9,0,0,825,826,7,6,0,0,826,196,
        1,0,0,0,827,828,7,14,0,0,828,829,7,4,0,0,829,830,7,13,0,0,830,198,
        1,0,0,0,831,832,7,2,0,0,832,833,7,9,0,0,833,834,7,18,0,0,834,835,
        7,9,0,0,835,836,7,3,0,0,836,200,1,0,0,0,837,838,7,2,0,0,838,839,
        7,16,0,0,839,840,7,0,0,0,840,841,7,1,0,0,841,202,1,0,0,0,842,843,
        7,18,0,0,843,844,7,0,0,0,844,845,7,8,0,0,845,846,7,5,0,0,846,847,
        7,16,0,0,847,204,1,0,0,0,848,849,7,18,0,0,849,850,7,0,0,0,850,851,
        7,3,0,0,851,852,7,8,0,0,852,853,7,11,0,0,853,206,1,0,0,0,854,855,
        7,18,0,0,855,856,7,0,0,0,856,857,7,20,0,0,857,858,7,22,0,0,858,859,
        7,0,0,0,859,860,7,2,0,0,860,861,7,17,0,0,861,862,7,4,0,0,862,208,
        1,0,0,0,863,864,7,18,0,0,864,865,7,4,0,0,865,866,7,5,0,0,866,867,
        7,10,0,0,867,868,7,4,0,0,868,210,1,0,0,0,869,870,7,18,0,0,870,871,
        7,9,0,0,871,872,7,6,0,0,872,873,7,22,0,0,873,874,7,0,0,0,874,875,
        7,2,0,0,875,876,7,17,0,0,876,877,7,4,0,0,877,212,1,0,0,0,878,879,
        7,18,0,0,879,880,7,17,0,0,880,881,7,2,0,0,881,882,7,3,0,0,882,883,
        7,9,0,0,883,884,5,95,0,0,884,885,7,21,0,0,885,886,7,16,0,0,886,887,
        7,9,0,0,887,888,7,6,0,0,888,214,1,0,0,0,889,890,7,6,0,0,890,891,
        7,16,0,0,891,216,1,0,0,0,892,893,7,6,0,0,893,894,7,16,0,0,894,895,
        7,1,0,0,895,896,7,4,0,0,896,218,1,0,0,0,897,898,7,6,0,0,898,899,
        7,16,0,0,899,900,7,3,0,0,900,220,1,0,0,0,901,902,7,6,0,0,902,903,
        7,17,0,0,903,904,7,2,0,0,904,905,7,2,0,0,905,222,1,0,0,0,906,907,
        7,16,0,0,907,908,7,6,0,0,908,224,1,0,0,0,909,910,7,16,0,0,910,911,
        7,6,0,0,911,912,7,2,0,0,912,913,7,13,0,0,913,226,1,0,0,0,914,915,
        7,16,0,0,915,916,7,15,0,0,916,917,7,3,0,0,917,918,7,9,0,0,918,919,
        7,16,0,0,919,920,7,6,0,0,920,921,7,0,0,0,921,922,7,2,0,0,922,228,
        1,0,0,0,923,924,7,16,0,0,924,925,7,5,0,0,925,230,1,0,0,0,926,927,
        7,16,0,0,927,928,7,5,0,0,928,929,7,1,0,0,929,930,7,4,0,0,930,931,
        7,5,0,0,931,232,1,0,0,0,932,933,7,15,0,0,933,934,7,5,0,0,934,935,
        7,9,0,0,935,936,7,18,0,0,936,937,7,0,0,0,937,938,7,5,0,0,938,939,
        7,13,0,0,939,234,1,0,0,0,940,941,7,15,0,0,941,942,7,5,0,0,942,943,
        7,16,0,0,943,944,7,19,0,0,944,945,7,9,0,0,945,946,7,2,0,0,946,947,
        7,4,0,0,947,236,1,0,0,0,948,949,7,15,0,0,949,950,7,5,0,0,950,951,
        7,16,0,0,951,952,7,21,0,0,952,953,7,4,0,0,953,954,7,8,0,0,954,955,
        7,3,0,0,955,238,1,0,0,0,956,957,7,5,0,0,957,958,7,1,0,0,958,959,
        7,19,0,0,959,960,7,10,0,0,960,961,7,5,0,0,961,962,7,0,0,0,962,963,
        7,15,0,0,963,964,7,11,0,0,964,240,1,0,0,0,965,966,7,5,0,0,966,967,
        7,4,0,0,967,968,7,0,0,0,968,969,7,1,0,0,969,242,1,0,0,0,970,971,
        7,5,0,0,971,972,7,4,0,0,972,973,7,2,0,0,973,244,1,0,0,0,974,975,
        7,5,0,0,975,976,7,4,0,0,976,977,7,6,0,0,977,978,7,0,0,0,978,979,
        7,18,0,0,979,980,7,4,0,0,980,246,1,0,0,0,981,982,7,5,0,0,982,983,
        7,4,0,0,983,984,7,3,0,0,984,985,7,17,0,0,985,986,7,5,0,0,986,987,
        7,6,0,0,987,248,1,0,0,0,988,989,7,5,0,0,989,990,7,16,0,0,990,991,
        7,2,0,0,991,992,7,2,0,0,992,993,7,12,0,0,993,994,7,0,0,0,994,995,
        7,8,0,0,995,996,7,14,0,0,996,250,1,0,0,0,997,998,7,5,0,0,998,999,
        7,16,0,0,999,1000,7,2,0,0,1000,1001,7,2,0,0,1001,1002,7,12,0,0,1002,
        1003,7,0,0,0,1003,1004,7,8,0,0,1004,1005,7,14,0,0,1005,1006,5,95,
        0,0,1006,1007,7,7,0,0,1007,1008,7,14,0,0,1008,1009,7,9,0,0,1009,
        1010,7,15,0,0,1010,1011,5,95,0,0,1011,1012,7,8,0,0,1012,1013,7,11,
        0,0,1013,1014,7,4,0,0,1014,1015,7,8,0,0,1015,1016,7,14,0,0,1016,
        1017,7,15,0,0,1017,1018,7,16,0,0,1018,1019,7,9,0,0,1019,1020,7,6,
        0,0,1020,1021,7,3,0,0,1021,252,1,0,0,0,1022,1023,7,7,0,0,1023,1024,
        7,4,0,0,1024,1025,7,23,0,0,1025,1026,7,17,0,0,1026,1027,7,4,0,0,
        1027,1028,7,6,0,0,1028,1029,7,8,0,0,1029,1030,7,4,0,0,1030,254,1,
        0,0,0,1031,1032,7,7,0,0,1032,1033,7,4,0,0,1033,1034,7,3,0,0,1034,
        256,1,0,0,0,1035,1036,7,7,0,0,1036,1037,7,11,0,0,1037,1038,7,16,
        0,0,1038,1039,7,5,0,0,1039,1040,7,3,0,0,1040,1041,7,4,0,0,1041,1042,
        7,7,0,0,1042,1043,7,3,0,0,1043,258,1,0,0,0,1044,1045,7,7,0,0,1045,
        1046,7,3,0,0,1046,1047,7,0,0,0,1047,1048,7,5,0,0,1048,1049,7,3,0,
        0,1049,260,1,0,0,0,1050,1051,7,7,0,0,1051,1052,7,3,0,0,1052,1053,
        7,0,0,0,1053,1054,7,5,0,0,1054,1055,7,3,0,0,1055,1056,7,7,0,0,1056,
        262,1,0,0,0,1057,1058,7,3,0,0,1058,1059,7,0,0,0,1059,1060,7,12,0,
        0,1060,1061,7,2,0,0,1061,1062,7,4,0,0,1062,264,1,0,0,0,1063,1064,
        7,3,0,0,1064,1065,7,11,0,0,1065,1066,7,4,0,0,1066,1067,7,6,0,0,1067,
        266,1,0,0,0,1068,1069,7,3,0,0,1069,1070,7,16,0,0,1070,268,1,0,0,
        0,1071,1072,7,3,0,0,1072,1073,7,5,0,0,1073,1074,7,0,0,0,1074,1075,
        7,6,0,0,1075,1076,7,7,0,0,1076,1077,7,0,0,0,1077,1078,7,8,0,0,1078,
        1079,7,3,0,0,1079,1080,7,9,0,0,1080,1081,7,16,0,0,1081,1082,7,6,
        0,0,1082,270,1,0,0,0,1083,1084,7,3,0,0,1084,1085,7,5,0,0,1085,1086,
        7,17,0,0,1086,1087,7,4,0,0,1087,272,1,0,0,0,1088,1089,7,3,0,0,1089,
        1090,7,13,0,0,1090,1091,7,15,0,0,1091,1092,7,4,0,0,1092,274,1,0,
        0,0,1093,1094,7,17,0,0,1094,1095,7,6,0,0,1095,1096,7,9,0,0,1096,
        1097,7,16,0,0,1097,1098,7,6,0,0,1098,276,1,0,0,0,1099,1100,7,17,
        0,0,1100,1101,7,6,0,0,1101,1102,7,24,0,0,1102,1103,7,9,0,0,1103,
        1104,7,6,0,0,1104,1105,7,1,0,0,1105,278,1,0,0,0,1106,1107,7,17,0,
        0,1107,1108,7,7,0,0,1108,1109,7,4,0,0,1109,280,1,0,0,0,1110,1111,
        7,24,0,0,1111,1112,7,11,0,0,1112,1113,7,4,0,0,1113,1114,7,6,0,0,
        1114,282,1,0,0,0,1115,1116,7,24,0,0,1116,1117,7,11,0,0,1117,1118,
        7,4,0,0,1118,1119,7,5,0,0,1119,1120,7,4,0,0,1120,284,1,0,0,0,1121,
        1122,7,24,0,0,1122,1123,7,9,0,0,1123,1124,7,3,0,0,1124,1125,7,11,
        0,0,1125,286,1,0,0,0,1126,1127,7,24,0,0,1127,1128,7,5,0,0,1128,1129,
        7,9,0,0,1129,1130,7,3,0,0,1130,1131,7,4,0,0,1131,288,1,0,0,0,1132,
        1133,7,20,0,0,1133,1134,7,16,0,0,1134,1135,7,5,0,0,1135,290,1,0,
        0,0,1136,1137,7,1,0,0,1137,1138,7,4,0,0,1138,1139,7,8,0,0,1139,1140,
        7,9,0,0,1140,1141,7,18,0,0,1141,1142,7,0,0,0,1142,1143,7,2,0,0,1143,
        292,1,0,0,0,1144,1145,5,42,0,0,1145,294,1,0,0,0,1146,1147,7,7,0,
        0,1147,1148,7,14,0,0,1148,1149,7,9,0,0,1149,1150,7,15,0,0,1150,296,
        1,0,0,0,1151,1152,5,33,0,0,1152,1153,5,61,0,0,1153,298,1,0,0,0,1154,
        1155,5,45,0,0,1155,300,1,0,0,0,1156,1157,5,33,0,0,1157,302,1,0,0,
        0,1158,1159,5,58,0,0,1159,304,1,0,0,0,1160,1165,5,34,0,0,1161,1164,
        3,369,184,0,1162,1164,3,307,153,0,1163,1161,1,0,0,0,1163,1162,1,
        0,0,0,1164,1167,1,0,0,0,1165,1163,1,0,0,0,1165,1166,1,0,0,0,1166,
        1168,1,0,0,0,1167,1165,1,0,0,0,1168,1179,5,34,0,0,1169,1174,5,39,
        0,0,1170,1173,3,349,174,0,1171,1173,3,307,153,0,1172,1170,1,0,0,
        0,1172,1171,1,0,0,0,1173,1176,1,0,0,0,1174,1172,1,0,0,0,1174,1175,
        1,0,0,0,1175,1177,1,0,0,0,1176,1174,1,0,0,0,1177,1179,5,39,0,0,1178,
        1160,1,0,0,0,1178,1169,1,0,0,0,1179,306,1,0,0,0,1180,1198,5,92,0,
        0,1181,1199,7,25,0,0,1182,1183,7,17,0,0,1183,1184,3,313,156,0,1184,
        1185,3,313,156,0,1185,1186,3,313,156,0,1186,1187,3,313,156,0,1187,
        1199,1,0,0,0,1188,1189,7,17,0,0,1189,1190,3,313,156,0,1190,1191,
        3,313,156,0,1191,1192,3,313,156,0,1192,1193,3,313,156,0,1193,1194,
        3,313,156,0,1194,1195,3,313,156,0,1195,1196,3,313,156,0,1196,1197,
        3,313,156,0,1197,1199,1,0,0,0,1198,1181,1,0,0,0,1198,1182,1,0,0,
        0,1198,1188,1,0,0,0,1199,308,1,0,0,0,1200,1209,3,321,160,0,1201,
        1205,3,317,158,0,1202,1204,3,315,157,0,1203,1202,1,0,0,0,1204,1207,
        1,0,0,0,1205,1203,1,0,0,0,1205,1206,1,0,0,0,1206,1209,1,0,0,0,1207,
        1205,1,0,0,0,1208,1200,1,0,0,0,1208,1201,1,0,0,0,1209,310,1,0,0,
        0,1210,1212,7,26,0,0,1211,1210,1,0,0,0,1212,312,1,0,0,0,1213,1216,
        3,315,157,0,1214,1216,3,311,155,0,1215,1213,1,0,0,0,1215,1214,1,
        0,0,0,1216,314,1,0,0,0,1217,1220,3,321,160,0,1218,1220,3,317,158,
        0,1219,1217,1,0,0,0,1219,1218,1,0,0,0,1220,316,1,0,0,0,1221,1224,
        3,319,159,0,1222,1224,2,56,57,0,1223,1221,1,0,0,0,1223,1222,1,0,
        0,0,1224,318,1,0,0,0,1225,1226,2,49,55,0,1226,320,1,0,0,0,1227,1228,
        5,48,0,0,1228,322,1,0,0,0,1229,1231,3,315,157,0,1230,1229,1,0,0,
        0,1231,1234,1,0,0,0,1232,1230,1,0,0,0,1232,1233,1,0,0,0,1233,1235,
        1,0,0,0,1234,1232,1,0,0,0,1235,1237,5,46,0,0,1236,1238,3,315,157,
        0,1237,1236,1,0,0,0,1238,1239,1,0,0,0,1239,1237,1,0,0,0,1239,1240,
        1,0,0,0,1240,324,1,0,0,0,1241,1245,3,327,163,0,1242,1244,3,329,164,
        0,1243,1242,1,0,0,0,1244,1247,1,0,0,0,1245,1243,1,0,0,0,1245,1246,
        1,0,0,0,1246,326,1,0,0,0,1247,1245,1,0,0,0,1248,1251,3,377,188,0,
        1249,1251,3,365,182,0,1250,1248,1,0,0,0,1250,1249,1,0,0,0,1251,328,
        1,0,0,0,1252,1255,3,345,172,0,1253,1255,3,361,180,0,1254,1252,1,
        0,0,0,1254,1253,1,0,0,0,1255,330,1,0,0,0,1256,1260,5,96,0,0,1257,
        1259,3,341,170,0,1258,1257,1,0,0,0,1259,1262,1,0,0,0,1260,1258,1,
        0,0,0,1260,1261,1,0,0,0,1261,1263,1,0,0,0,1262,1260,1,0,0,0,1263,
        1265,5,96,0,0,1264,1256,1,0,0,0,1265,1266,1,0,0,0,1266,1264,1,0,
        0,0,1266,1267,1,0,0,0,1267,332,1,0,0,0,1268,1270,3,335,167,0,1269,
        1268,1,0,0,0,1270,1271,1,0,0,0,1271,1269,1,0,0,0,1271,1272,1,0,0,
        0,1272,334,1,0,0,0,1273,1286,3,363,181,0,1274,1286,3,367,183,0,1275,
        1286,3,371,185,0,1276,1286,3,373,186,0,1277,1286,3,339,169,0,1278,
        1286,3,359,179,0,1279,1286,3,357,178,0,1280,1286,3,355,177,0,1281,
        1286,3,343,171,0,1282,1286,3,375,187,0,1283,1286,7,27,0,0,1284,1286,
        3,337,168,0,1285,1273,1,0,0,0,1285,1274,1,0,0,0,1285,1275,1,0,0,
        0,1285,1276,1,0,0,0,1285,1277,1,0,0,0,1285,1278,1,0,0,0,1285,1279,
        1,0,0,0,1285,1280,1,0,0,0,1285,1281,1,0,0,0,1285,1282,1,0,0,0,1285,
        1283,1,0,0,0,1285,1284,1,0,0,0,1286,336,1,0,0,0,1287,1288,5,47,0,
        0,1288,1289,5,42,0,0,1289,1295,1,0,0,0,1290,1294,3,347,173,0,1291,
        1292,5,42,0,0,1292,1294,3,353,176,0,1293,1290,1,0,0,0,1293,1291,
        1,0,0,0,1294,1297,1,0,0,0,1295,1293,1,0,0,0,1295,1296,1,0,0,0,1296,
        1298,1,0,0,0,1297,1295,1,0,0,0,1298,1299,5,42,0,0,1299,1317,5,47,
        0,0,1300,1301,5,47,0,0,1301,1302,5,47,0,0,1302,1306,1,0,0,0,1303,
        1305,3,351,175,0,1304,1303,1,0,0,0,1305,1308,1,0,0,0,1306,1304,1,
        0,0,0,1306,1307,1,0,0,0,1307,1310,1,0,0,0,1308,1306,1,0,0,0,1309,
        1311,3,359,179,0,1310,1309,1,0,0,0,1310,1311,1,0,0,0,1311,1314,1,
        0,0,0,1312,1315,3,371,185,0,1313,1315,5,0,0,1,1314,1312,1,0,0,0,
        1314,1313,1,0,0,0,1315,1317,1,0,0,0,1316,1287,1,0,0,0,1316,1300,
        1,0,0,0,1317,338,1,0,0,0,1318,1319,7,28,0,0,1319,340,1,0,0,0,1320,
        1321,8,29,0,0,1321,342,1,0,0,0,1322,1323,7,30,0,0,1323,344,1,0,0,
        0,1324,1325,7,31,0,0,1325,346,1,0,0,0,1326,1327,8,32,0,0,1327,348,
        1,0,0,0,1328,1329,8,33,0,0,1329,350,1,0,0,0,1330,1331,8,34,0,0,1331,
        352,1,0,0,0,1332,1333,8,35,0,0,1333,354,1,0,0,0,1334,1335,7,36,0,
        0,1335,356,1,0,0,0,1336,1337,7,37,0,0,1337,358,1,0,0,0,1338,1339,
        7,38,0,0,1339,360,1,0,0,0,1340,1341,7,39,0,0,1341,362,1,0,0,0,1342,
        1343,7,40,0,0,1343,364,1,0,0,0,1344,1345,7,41,0,0,1345,366,1,0,0,
        0,1346,1347,7,42,0,0,1347,368,1,0,0,0,1348,1349,8,43,0,0,1349,370,
        1,0,0,0,1350,1351,7,44,0,0,1351,372,1,0,0,0,1352,1353,7,45,0,0,1353,
        374,1,0,0,0,1354,1355,7,46,0,0,1355,376,1,0,0,0,1356,1357,7,47,0,
        0,1357,378,1,0,0,0,1358,1359,9,0,0,0,1359,380,1,0,0,0,28,0,1163,
        1165,1172,1174,1178,1198,1205,1208,1211,1215,1219,1223,1232,1239,
        1245,1250,1254,1260,1266,1271,1285,1293,1295,1306,1310,1314,1316,
        0
    ];

    private static __ATN: antlr.ATN;
    public static get _ATN(): antlr.ATN {
        if (!CypherLexer.__ATN) {
            CypherLexer.__ATN = new antlr.ATNDeserializer().deserialize(CypherLexer._serializedATN);
        }

        return CypherLexer.__ATN;
    }


    private static readonly vocabulary = new antlr.Vocabulary(CypherLexer.literalNames, CypherLexer.symbolicNames, []);

    public override getVocabulary(): antlr.Vocabulary {
        return CypherLexer.vocabulary;
    }

    private static readonly decisionsToDFA = CypherLexer._ATN.decisionToState.map( (ds: antlr.DecisionState, index: number) => new antlr.DFA(ds, index) );
}